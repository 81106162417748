@use "../../scss/" as *;
.tf-section.tf-roadmap {
  background: url("../../assets/images/background/bg1roadmap.png") no-repeat;
  padding-bottom: 0;

  &.style2 {
    background: #0c1226;
  }
  &.style3 {
    background: url("../../assets/images/background/bg-5.jpg") center center
      fixed no-repeat;
    background-size: cover;
  }
}
.roadmap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 1010px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 40px;
  margin-top: 30px;
  position: relative;

  &::after {
    width: 6px;
    height: 50%;
    background: $color-main2;
    position: absolute;
    top: 0;
    left: 49.8%;
    content: "";
    z-index: -1;
  }
  &::before {
    width: 6px;
    height: 124%;
    background: $bg-2;
    position: absolute;
    top: 46%;
    left: 49.8%;
    content: "";
    z-index: 1;
  }

  .rm-box {
    position: relative;
    margin-bottom: 115px;
    width: 403px;
    &::after,
    &::before {
      @include topcenter;
      position: absolute;
      content: "";
      background: $bg-2;
    }
    &.left {
      margin-top: 0;
      &::before {
        right: -52px;
      }
      &::after {
        right: -72px;
      }
    }
    &.right {
      margin-bottom: -46px;
      margin-top: 165px;
      &::before {
        left: -52px;
      }
      &::after {
        left: -72px;
      }
    }
    &::after {
      min-width: 20px;
      height: 20px;
    }
    &::before {
      width: 52px;
      height: 2px;
    }
    .corner-box {
      position: relative;
      background: #0e2431;
      padding: 18px;
      &.active,
      &:hover {
        box-shadow: 0 0px 32px 0 rgb(34 183 143 / 50%);
      }
      h5 {
        margin-bottom: 18px;
      }
      &::before {
        width: calc(105% - 3rem);
      }
      &:hover::before {
        width: 0;
      }
      &::before,
      &::after {
        background: #0e2431;
      }

      &.normal {
        background: #13182b;
        &::before,
        &::after {
          background: #13182b;
        }
      }
    }

    ul {
      li {
        padding-left: 26px;
        position: relative;
        &::before {
          position: absolute;
          left: 10px;
          top: 13px;
          content: "";
          width: 4px;
          height: 4px;
          border-radius: 2px;
          background: $color-main1;
        }
      }
    }
  }

  @include tablet {
    width: 100%;

    .rm-box {
      width: 42.2%;
    }
  }
  .corner-box {
    &::after {
      height: calc(100% - 3rem);
      width: calc(100% + 0px);
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
    }
    &::after,
    &::before {
      background: #0e2432;
    }
  }
  &.scrol {
    height: 750px;
    overflow-y: auto;
    @include mobile {
      height: 650px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    &:hover {
      cursor: s-resize;
    }
  }
  &.style2 {
    width: 100%;
    margin-top: 28px;
    .swiper {
      width: 1330px;
      overflow: unset;

      @include tablet {
        width: 100%;
        overflow: hidden;
      }
    }
    .box {
      width: 100%;
      position: relative;
      padding-top: 53px;
      padding-bottom: 48px;
      .rm-box {
        background: #0e2230;
        margin-bottom: 0;
        &::after,
        &::before {
          display: none;
        }

        .corner-box.normal {
          background: #13182b;
          &::before,
          &::after {
            background: #13182b;
          }
        }
      }
      &::before,
      &::after {
        @include leftcenter;
        position: absolute;
        background: $bg-2;
        content: "";
      }
      &::after {
        min-width: 20px;
        height: 20px;
        top: -7px;
      }
      &.active {
        &::before {
          height: 50%;
        }
        .icon {
          display: none;
        }
      }
      &::before {
        width: 2px;
        height: 100%;
        top: 10px;
      }
      .corner-box {
        background: #0e2230;
        &::after,
        &::before {
          background: #0e2230;
        }
        &::before {
          height: calc(100% + 0px);
          top: 0px;
          left: 50%;
          transform: translateX(-50%);
        }
        &::after {
          height: calc(108% - 3rem);
          width: calc(100% + 4px);
          left: -2px;
          top: 50%;
          transform: translateY(-50%);
        }
        &.active,
        &:hover {
          &::after {
            height: 0;
          }
        }
      }
      &.style {
        &::after,
        &::before {
          background: $color-main2;
        }
        .corner-box {
          background: #13182b;
          &::after,
          &::before {
            background: #13182b;
          }
          &::before {
            height: calc(100% + 0px);
            top: 0px;
            left: 50%;
            transform: translateX(-50%);
          }
          &::after {
            height: calc(108% - 3rem);
            width: calc(100% + 4px);
            left: -2px;
            top: 50%;
            transform: translateY(-50%);
          }
          &:hover {
            &::after {
              height: 0;
            }
          }
        }
        .icon {
          background: $color-main2;
        }
      }
      .icon {
        min-width: 20px;
        height: 20px;
        bottom: -11px;
        position: absolute;
        @include leftcenter;
        background: $bg-2;
      }
    }
    &::after {
      width: 33%;
      height: 6px;
      right: -15px;
      top: 0;
      left: auto;
      z-index: 0;
    }
    &::before {
      width: 76%;
      height: 6px;
      left: -15px;
      top: 0;
    }
    &.style3 {
      .swiper {
        width: 1594px;
        overflow: unset;

        @include tablet {
          width: 100%;
          overflow: hidden;
        }
      }
      &::before {
        width: 65%;
      }
      &::after {
        width: 37%;
      }
      .box {
        padding: 87px 58px 43px 62px;
        &::before,
        &::after {
          left: 0;
        }
        &::before {
          height: 53%;
        }
        .rm-box {
          position: initial;
          background: none;
          &::after {
            top: 56.2%;
            min-width: 20px;
            height: 20px;
            left: -10px;
            position: absolute;
            content: "";
            background: $bg-2;
            display: block;
          }
          &::before {
            top: 56.2%;
            height: 2px;
            width: 53px;
            left: 10px;
            background: $bg-2;
            position: absolute;
            content: "";
            display: block;
          }
          .corner-box {
            // background: url('./../../assets/images/backgroup/4.png') center center no-repeat;
            background-size: cover;
            &::before,
            &::after {
              // background: url('./../../assets/images/backgroup/4.png') center center no-repeat;
              background-size: cover;
            }
            &::before {
              height: calc(100% + 4px);
              top: -2px;
            }
            &::after {
              height: calc(100% - 3rem);
              width: calc(100% + 0px);
              left: 0px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
        &.style {
          .rm-box {
            &::after,
            &::before {
              background: $color-main2;
            }
            .corner-box {
              // background: url('./../../assets/images/backgroup/5.png') center center no-repeat;
              background-size: cover;
              &::before,
              &::after {
                // background: url('./../../assets/images/backgroup/5.png') center center no-repeat;
                background-size: cover;
              }
            }
          }
        }
      }
    }
    &:hover {
      cursor: ew-resize;
    }

    @include tablet {
      .rm-box {
        width: 100%;
      }
    }
  }

  @include mobile {
    justify-content: flex-start;

    &::before,
    &::after {
      left: 0;
    }
    &::before {
      height: 250%;
    }
    .rm-box {
      margin-bottom: 80px;
      width: 100%;
      &.right {
        margin-top: 0;
        margin-bottom: 80px;
      }

      &.left {
        &::before {
          right: auto;
          left: -34px;
        }
      }
    }
  }
}

.page-roadmap .tf-roadmap {
  background: url("../../assets/images/background/bg-roadmap.png") no-repeat;
  background-position: bottom center;
  background-size: cover;

  padding: 80px 0 150px;

  .roadmap {
    overflow: unset;
    height: 100%;
  }
}

.page-roadmap-v2 .tf-roadmap {
  background: $bg-main !important;
}
.home-2 .tf-roadmap {
  padding: 90px 0;
}
.home-3 .tf-roadmap {
  padding: 80px 0 135px;
}

.page-roadmap .roadmap {
  &::before {
    height: 50%;
  }
}

.page-roadmap-v2 {
  .tf-roadmap {
    padding-bottom: 55px;
  }
  .roadmap {
    .swiper-button-prev,
    .roadmap .swiper-button-next {
      top: 88%;
    }
    &.roadmap.style2.style3 .box {
      padding-bottom: 120px;
    }
  }
}
